import { HeadFC, Link } from "gatsby";
import * as React from "react";

import PageLayout from "../components/pageLayout";
import { PageSection } from "../components/pageSection";
import { HeadTemplate } from "../templates/headTemplate";

const NotFoundPage = () => {
  return (
    <PageLayout>
      <PageSection>
        <h1>Page not found</h1>
        <p>
          Hmmm. It looks like you've tried to visit a page that doesn't exist.
          The website was recently updated, so it's possible that you followed
          an old link or bookmark for the old site.
        </p>
        <p>
          Head back to the <Link to="/">home page</Link> or use the navigation
          menu to get back on track.
        </p>
      </PageSection>
    </PageLayout>
  );
};

export default NotFoundPage;

export const Head: React.FC = () => HeadTemplate({ title: "Page not found" });
